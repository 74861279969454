<template>
  <v-row justify="center" v-if="mostrar">
    <v-dialog v-model="mostrar" persistent max-width="650">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title>
            <span class="subtitle-1" v-html="compTitulo"></span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="Nombre de la Categoría"
                    required
                    dense
                    v-model="localData.cate_name"
                    autocomplete="new-cate_name"
                    :rules="[v => !!v || 'Debe escribir un texto']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-card>
                <v-app-bar dense elevation="0" outlined>
                  <v-toolbar-title class="text-subtitle-1">Imágenes</v-toolbar-title>
                  <v-btn elevation="2" icon small class="ml-2" @click="onClickAddAdjunto">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-app-bar>
                <v-card-text>
                  <v-row v-for="(adjunto, index) in localData.specialtiesCategoriesFiles" :key="'scf'+index">
                    <v-col class="mb-2">
                      <span class="body-1 mr-2">Imagen:</span>
                      <v-chip label color="primary" outlined @click="onClickDescargarAdjunto(adjunto)">
                        {{ adjunto.cafi_name }}
                      </v-chip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-btn elevation="2" icon small color="error" class="ml-2" v-on="on" @click="onClickEliminarAdjunto(index)">
                            <v-icon>mdi-trash-can</v-icon>
                          </v-btn>
                        </template>
                        <span>Eliminar imagen</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="mt-1" dense v-for="(adjunto, index) in localData.adjuntos" :key="'a'+index">
                    <v-col class="d-flex align-center" cols="12" v-if="adjunto.id==null">
                      <v-file-input multiple dense outlined label="Imagen" accept="image/png, image/jpeg, image/svg+xml" @change="onChangeAdjunto($event, index)"></v-file-input>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cerrarForm">Cerrar</v-btn>
            <v-btn
              type="submit"
              color="blue darken-1"
              text
              @click.prevent="guardarCategoria"
              >Guardar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-row>
</template>

<script>
import { cateSvr } from "@/_services/";
import { authHeader } from "@/_helpers";
import { config } from "@/_config";
import {mapActions} from "vuex";

export default {
  name: "categoria-form-comp",
  props: ["datos"],
  data: () => ({
    valid: true,
    mostrar: false,
    localData: { cate_name: null },
    editando: false
  }),
  methods: {
    ...mapActions("comun", ["setSnackBar"]),
    /* funcion para cerrar el form */
    cerrarForm() {
      this.$emit("cerrar-form");
    },
    // async guardarCategoria() {
    //   this.$refs.form.validate();
    //   if (!this.valid) {
    //     return;
    //   }
    //
    //   let tmp = {
    //     cate_name: this.localData.cate_name.trim()
    //   };
    //
    //   try {
    //     if (this.editando) {
    //       // si  los datos han cambiado actualizamos
    //       if (this.datos.datos.cate_name !== tmp.cate_name) {
    //         tmp = this.localData;
    //         await cateSvr.cateUpdate(tmp.cate_id, tmp);
    //         this.$emit("actualizar-datos");
    //       }
    //     } else {
    //       await cateSvr.cateCreate(tmp);
    //       this.$emit("actualizar-datos");
    //     }
    //     this.cerrarForm();
    //   } catch (error) {
    //     // eslint-disable-next-line no-console
    //     console.log(error);
    //   }
    // },
    guardarCategoria(){
      this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      const formData = new FormData();
      formData.append('datos', JSON.stringify(this.localData));

      this.localData.adjuntos.forEach(element => {
        //Subidor Multiple
        if(element.file!=null){
          element.file.forEach(element2 =>{
            formData.append('adjuntos[]', element2);
          });
        }
      });

      const miConfig = {
        headers: {
          "Content-Type": "multipart/form-data",
          ...authHeader()
        },
      };
      const url = `${config.apiUrl}/setCategoria`;

      axios
          .post(url, formData, miConfig)
          .then(response => {
              this.$emit("actualizar-datos");
              this.cerrarForm();
          })
          .catch(error => {
            console.log(error);
          });

    },
    onClickAddAdjunto(){
      this.localData.adjuntos.push({id: null, file: null});
    },
    onChangeAdjunto(file, index){
      this.localData.adjuntos[index]['file']=file;
    },
    onClickEliminarAdjunto(index){
      const miConfig = {
        headers: {
          "Content-Type": "application/json",
          ...authHeader()
        }
      };
      const url = `${config.apiUrl}/adjuntoCategoria/${this.localData.specialtiesCategoriesFiles[index].cafi_id}`;

      axios
          .delete(url, miConfig)
          .then(response => {
            const miSnack = {
              mostrar: true,
              texto: "Imagen eliminada correctamente",
              color: "success",
            };
            this.setSnackBar(miSnack);
            this.localData.specialtiesCategoriesFiles.splice(index, 1);
          })
          .catch(error => {
            console.log(error);
          });
    },
    onClickDescargarAdjunto(adjunto){
      const miConfig = {
        headers: {
          "Content-Type": "application/json",
          ...authHeader()
        },
        responseType: 'blob'
      };
      const url = `${config.apiUrl}/adjuntoCategoria/${adjunto.cafi_id}`;

      axios
          .get(url, miConfig)
          .then(response => {
            const type = response.headers["content-type"];
            const url = window.URL.createObjectURL(new Blob([response.data], { type }));
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch(error => {
            console.log(error);
          });
    },
  },
  computed: {
    compTitulo() {
      return this.datos.editar
        ? `Editando la categoría: ${this.localData.cate_name} `
        : "Crear nueva categoría";
    }
  },
  mounted() {
    this.localData = { ...this.datos.datos };
    this.$set(this.localData, 'adjuntos', []);
    if (this.datos.datos) {
      this.editando = true;
    }
    this.mostrar = true;
  }
};
</script>

<style scoped></style>
